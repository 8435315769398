// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  merchant: {
    "apiLogin": "pRRXKOl8ikMmt9u",
    "apiKey": "4Vj8eK4rloUd272L48hsrarnUA"
 },
  firebase: {
    apiKey: "AIzaSyDl0Ozp78UXnlUuH6XWqt8iK-T6pdS8TXU",
    authDomain: "talentoclaro-acd47.firebaseapp.com",
    projectId: "talentoclaro-acd47",
    storageBucket: "talentoclaro-acd47.appspot.com",
    messagingSenderId: "691499797824",
    appId: "1:691499797824:web:06881b3cb3d75c7a79fea5"
  },
  linkRefered: "https://masters.wtredata.com.co",
  linkPayu: 'https://api.masters.wtredata.com.co/consulta',
  linkPayment: 'https://api.masters.wtredata.com.co/getMasters',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
