import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @HostBinding('class') classes: string = 'main-footer';
  public appVersion = '3.2.0';
  public currentYear: any = new Date().getFullYear();

  constructor() { }

  ngOnInit(): void {
  }

}
