import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commision'
})
export class CommisionPipe implements PipeTransform {

  transform(value: any[], ...args: unknown[]): number {
    let suma = 0;
    value.forEach((element:any) => {
      if (element.value != -1) {
        if (element.value == 14) {
          suma += 2;
        }
        if (element.value == 49) {
          suma += 12;
        }
        if (element.value == 130) {
          suma += 30;
        }
      }
    });
    return suma;
  }

}
