import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterRef'
})
export class FilterRefPipe implements PipeTransform {

  transform(value: any[], filter: string, ...args: unknown[]): any[] {
    if (filter == '') {
      return value;
    }

    return value.filter((item: any) => {
      return item.parent.toLowerCase().includes(filter.toLowerCase());
    });
  }

}
