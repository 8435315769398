import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PrivateComponent } from './private.component';
import { PrivateRoutingModule } from './private-routing.module';
import { HomeComponent } from './home/home.component';
import { ComponentsModule } from '../core/components/components.module';
import { BlankComponent } from './blank/blank.component';
import { SubMenuComponent } from './main-menu/sub-menu/sub-menu.component';
import {TableModule} from 'primeng/table';
import {SliderModule} from 'primeng/slider';
import {ButtonModule} from 'primeng/button';
import { RegisterComponent } from './register/register.component';
import {InputTextModule} from 'primeng/inputtext';
import {DropdownModule} from 'primeng/dropdown';
import {FormsModule} from '@angular/forms';
import { ToastLinkComponent } from '../core/components/toast-link/toast-link.component';
import {PasswordModule} from 'primeng/password';
import { PipeModule } from '../core/pipe/pipe.module';
import { CalendarModule } from 'primeng/calendar';
import {BadgeModule} from 'primeng/badge';
import { Angular2CsvModule } from 'angular2-csv';
import { FilterPipe } from '../core/pipe/filter.pipe';
import { FilterRefPipe } from '../core/pipe/filter-ref.pipe';
import { UpdateComponent } from './update/update.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';

@NgModule({
  declarations: [
    PrivateComponent,
    HomeComponent,
    BlankComponent,
    SubMenuComponent,
    RegisterComponent,
    ToastLinkComponent,
    UpdateComponent
  ],
  imports: [
    CommonModule,
    PrivateRoutingModule,
    ComponentsModule,
    PipeModule,
    TableModule,
    SliderModule,
    ButtonModule,
    InputTextModule,
    DropdownModule,
    FormsModule,
    PasswordModule,
    CalendarModule,
    Angular2CsvModule,
    BadgeModule,
    ConfirmDialogModule
  ],
  providers: [
    FilterPipe,
    DatePipe,
    FilterRefPipe
  ]
})
export class PrivateModule { }
