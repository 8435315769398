<div class="container-fluid">
    <div class="wrapper fadeInDown">
        <div id="formContent">
            <div class="fadeIn first">
                <img src="../../assets/img/logo.svg" id="icon" alt="User Icon" />
            </div>
            <br><br>
            <div>
                <div class="row">
                    <div class="col">
                        <input type="text" pInputText [(ngModel)]="user" placeholder="Nombre de usuario" /> <br>
                        <span class="label">Escribe tu correo electrónico.</span>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <p-password [(ngModel)]="password" [toggleMask]="true" [feedback]="false" placeholder="Contraseña"></p-password><br>
                        <span class="label">Escribe la contraseña registrada.</span>
                    </div>
                </div>
            </div>
            <input type="submit" class="fadeIn fourth" value="Iniciar Sesión" (click)="send()">
            <br><br>
            <div *ngIf="url != -1">
              ¿Aún no tienes una cuenta como referidor? <a [routerLink]="['/refer']">Registrate aquí!!</a>
            </div>
        </div>
    </div>
</div>
