
<div class="row ">
    <div class="offset-8 col-4 my-3">
        <button pButton (click)="copy()" *ngIf="type != '71'" type="button" label="Genera aquí el enlace para compartir a tus referidos" style="background-color: #8DB91E;"></button>
    </div>
</div>
<div class="card px-4">
    <h5 class="text-center pt-3 pb-3">Lista de Referidos</h5>
    <div class="row mb-4" >
        <div class="col-md-2 sm-12" >
          <br>
          <h3 class="text-center">Comisión Total: {{((users| filterRef: filter) |filter: value:value1)|commision | currency}}</h3>
        </div>
        <div class="col-md-2 sm-12 w-full d-flex justify-content-center mt-auto" id="buttoncsv">
          <angular2csv  [data]="data" filename="Registros" [options]="options" *ngIf="type=='71'"></angular2csv>
        </div>
        <div class="col-md-2 sm-12" style="display: grid;">
          Fecha Inicio:
            <p-calendar [(ngModel)]="value" (onSelect)="getData()"></p-calendar>
        </div>
        <div class="col-md-2 sm-12" style="display: grid;">
          Fecha Fin:
            <p-calendar [(ngModel)]="value1" (onSelect)="getData()"></p-calendar>
        </div>
        <div class="col-md-2 sm-12" style="display: grid;" *ngIf="type == '71'">
            Buscar Referidor:  <input pInputText type="text" [(ngModel)]="filter" (ngModelChange)="getData()">
        </div>
        <div class="col-md-2 sm-12" style="display: grid;"><br>
            <button pButton type="button" label="Limpiar Filtros" (click)="value=null;value1 = null;filter=''" style="background-color: #8DB91E"></button>
        </div>
    </div>
    <p-confirmDialog #cd [style]="{width: '50vw'}">
        <ng-template pTemplate="header">
            <h3>Confirmación</h3>
        </ng-template>
        <ng-template pTemplate="footer">
            <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()" style="background-color: #8DB91E;"></button>
            <button type="button" pButton icon="pi pi-check" label="Si" (click)="cd.accept()" style="background-color: darkred;"></button>
        </ng-template>
    </p-confirmDialog>
    <p-table [value]="(users| filterRef: filter) |filter: value:value1" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" responsiveLayout="scroll" currentPageReportTemplate="Mostrando {first} hasta {last} de {totalRecords} Registros" [rowsPerPageOptions]="[10, 25, 50]">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="date">
                    Fecha
                    <p-sortIcon field="date"></p-sortIcon>
                </th>
                <th pSortableColumn="doc">
                    Documento
                    <p-sortIcon field="doc"></p-sortIcon>
                </th>
                <th pSortableColumn="name">
                    Nombre
                    <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th pSortableColumn="surname">
                    Apellido
                    <p-sortIcon field="surname"></p-sortIcon>
                </th>
                <th pSortableColumn="city">
                    Ciudad
                    <p-sortIcon field="city"></p-sortIcon>
                </th>
                <th pSortableColumn="country">
                    País
                    <p-sortIcon field="country"></p-sortIcon>
                </th>
                <th pSortableColumn="parent" *ngIf="type == '71'">
                    Referidor
                    <p-sortIcon field="parent"></p-sortIcon>
                </th>
                <th pSortableColumn="value">
                    Valor de Pago
                    <p-sortIcon field="value"></p-sortIcon>
                </th>
                <th>
                    Comisión
                </th>
                <th>
                    Acción
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
            <tr>
                <td>{{ user.date | date }} </td>
                <td>{{ user.doc }}</td>
                <td>{{ user.name }}</td>
                <td>{{ user.surname }}</td>
                <td>{{ user.city }}</td>
                <td>{{ user.country }}</td>
                <td *ngIf="type == '71'">
                    {{ user.parent }}
                </td>
                <td>
                  <p-badge value="{{0 | currency}}" size="large" severity="danger" *ngIf="user.value == -1 || user.value ==undefined"></p-badge>
                  <p-badge value="{{user.value | currency}}" size="large" severity="success" *ngIf="user.value>1  && user.value !=undefined"></p-badge>
                </td>
                <td>
                  <p-badge value="{{calculateComission(user.value) | currency}}" size="large" severity="danger" *ngIf="user.value == -1 || user.value ==undefined"></p-badge>
                  <p-badge value="{{calculateComission(user.value) | currency}}" size="large" severity="success" *ngIf="user.value>1  && user.value !=undefined"></p-badge>
                </td>
                <td>
                  <p-button (click)="confirm(user.id)" label="Borrar Datos" con="pi pi-times" styleClass="mr-2 p-button-danger" ></p-button>
                </td>
            </tr>
        </ng-template>
        <!-- <ng-template pTemplate="paginatorleft">
            <p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button>
        </ng-template> -->
        <!-- <ng-template pTemplate="paginatorright">
            <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button>
        </ng-template> -->
    </p-table>

</div>

