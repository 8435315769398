<!-- Brand Logo -->
<a [routerLink]="['/']" class="brand-link  text-center">
    <!-- <img src="assets/img/logo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: 0.8" /> -->
    <span class="brand-text font-weight-light">Admin</span>
</a>

<!-- Sidebar -->
<div class="sidebar">
    <!-- Sidebar user (optional) -->
    <div class="user-panel my-4 d-flex justify-content-center">
        <div class="image">
            <img [src]="'assets/img/default-profile.png'" class="img-circle elevation-2" alt="User Image" />
        </div>
        <div class="info">
            <label class="color-white d-block">
                {{name}}
            </label>
            <!-- <a [routerLink]="['/profile']" class="d-block">
                Admin
            </a> -->
        </div>
    </div>

    <!-- Sidebar Menu -->
    <nav class="mt-2" style="overflow-y: hidden">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <app-menu-item class="w-100" *ngFor="let item of menu" [menuItem]="item"></app-menu-item>
            <a (click)="close()" class="nav-link w-100 closeSession">
                <i class="nav-icon fas fa-times mr-3"></i>
                <p> Cerrar sesión</p>
            </a>
        </ul>
    </nav>
</div>