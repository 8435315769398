import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicRoutingModule } from './public-routing.module';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { PasswordModule } from 'primeng/password';
import { CreateReferComponent } from './create-refer/create-refer.component';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';



@NgModule({
  declarations: [
    LoginComponent,
    CreateReferComponent
  ],
  imports: [
    CommonModule,
    PublicRoutingModule,
    PasswordModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    DropdownModule
  ]
})
export class PublicModule { }
