import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ControlService } from 'src/app/core/services/control.service';

interface Type {
  name: string,
  code: number
}

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  selectedType: Type = {
    name: '',
    code: 1
  };

  id = sessionStorage.getItem("id");

  types = [
    {name: 'Natural', code: 1},
    {name: 'Jurídica', code: 2},
  ]

  selected_refer = {
    type: '',
    name_entity: '',
    name: '',
    surname: '',
    mail: '',
    password: '',
    phone: '',
    type_user: '1'
  };

  data_backup = {
    type: '',
    name_entity: '',
    name: '',
    surname: '',
    mail: '',
    password: '',
    phone: '',
    type_user: '1'
  };

  refers = [];

  password = '';

  constructor(private control: ControlService, private router: Router) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    if(this.id != undefined){
      this.control.getUser(this.id).then((res:any) => {
        this.data_backup = res;
        this.selected_refer.name_entity = res.name_entity;
        this.selected_refer.mail = res.mail;
        this.selected_refer.name = res.name;
        this.selected_refer.password = res.password;
        this.selected_refer.phone = res.phone;
        this.selected_refer.surname = res.surname;
        this.selected_refer.type_user = res.type_user;
        this.selected_refer.type = res.type;
        this.selectedType = this.types.filter(t => t.name == res.type)[0];
        this.password = res.password;
      });
    }
  }


  send(){
    if(this.id != undefined){
      this.selected_refer.type = this.selectedType.name;

      if(this.data_backup.password != this.selected_refer.password){

        if (this.password != this.selected_refer.password) {
          alert ("Las contraseñas no coinciden");
          return;
        }
        this.selected_refer.password = btoa(this.selected_refer.password);

      }


      if(this.selected_refer.type == 'Natural'){
        this.selected_refer.name_entity = '';
      }

      this.control.updateUser(this.id, this.selected_refer).then((res: any) => {
        alert ("Los datos han sido actualizados");
        this.getData();
      });

    }

  }

}
