import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

export const MENU1 = [
  {
      name: 'Referidos',
      path: ['/home']
  },
  {
      name: 'Referidores',
      path: ['/register']
  },
  {
      name: 'Actualizar Datos',
      path: ['/update']
  }
];

export const MENU2 = [
    {
        name: 'Registros',
        path: ['/home']
    },
    {
        name: 'Actualizar Datos',
        path: ['/update']
    }
  ];

@Component({
  selector: 'app-menu-side-bar',
  templateUrl: './menu-side-bar.component.html',
  styleUrls: ['./menu-side-bar.component.scss']
})
export class MenuSideBarComponent implements OnInit {

    session = sessionStorage.getItem("type");
    name = sessionStorage.getItem('name')

  public menu = MENU1;

  constructor(private router: Router) {

  }

  ngOnInit(): void {
    const name = sessionStorage.getItem('name_entity');
    if (name) {
      this.name = name;
    }
    if (this.session == '71') {
        this.menu = MENU1;
    }else{
        this.menu = MENU2;
    }
  }

  close(){
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }


}
