<div class="pt-5" *ngIf="create">
    <div class="card py-4" style="width: 70%; margin-left: auto; margin-right: auto; padding-left: 5%;padding-right: 5%;">
        <h5 class="text-center">Registra aquí los datos del Referidor</h5>
        <br>
        <div class="container">
            <div class="row">
                <div class="col">
                    <p-dropdown [options]="types" [style]="{'width':'100%'}" [(ngModel)]="selectedType" optionLabel="name"></p-dropdown>
                </div>
                <div class="col">
                    <span class="p-float-label">
                        <input id="float-input1" type="phone" [(ngModel)]="selected_refer.phone" required pInputText>
                        <label for="float-input1">Telefono</label>
                    </span>
                </div>
                <div class="col" *ngIf="selectedType.code =='2'">
                    <span class="p-float-label">
                    <input id="float-input2" type="text" [(ngModel)]="selected_refer.name_entity" required pInputText>
                    <label for="float-input2">Nombre Entidad</label>
                </span>
                </div>
                <div class="col">
                    <span class="p-float-label">
                    <input id="float-input3" type="name" [(ngModel)]="selected_refer.name" required pInputText>
                    <label for="float-input3">Nombres</label>
                </span>
                </div>
                <div class="col">
                    <span class="p-float-label">
                    <input id="float-input4" type="text" [(ngModel)]="selected_refer.surname" required pInputText>
                    <label for="float-input4">Apellidos</label>
                </span>
                </div>
                <div class="col">
                    <span class="p-float-label">
                        <input id="float-input5" type="email" [(ngModel)]="selected_refer.mail" required pInputText>
                        <label for="float-input5">Correo</label>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <span class="p-float-label">
                    <input id="float-input6" type="password" [(ngModel)]="selected_refer.password" pPassword>
                    <label for="float-input6">Contraseña</label>
                </span>
                </div>
                <div class="col">
                    <span class="p-float-label">
                    <input id="float-input7" type="password" [(ngModel)]="password" pPassword>
                    <label for="float-input7">Repite contraseña</label>
                </span>
                </div>
            </div>
            <div class="row d-flex justify-content-between">
                <button pButton type="button" label="Cancelar" class="p-button bg-btn-gray" (click)="create=!create"></button>
                <button pButton type="button" label="Registrar" class="p-button bg-btn-primary" (click)="send()"></button>
            </div>
        </div>
        <br>
    </div>

</div>

<div class="card px-4" *ngIf="!create">
    <h5 class="text-center pt-3 pb-3">Lista de Referidores</h5>
    <div class="w-full d-flex justify-content-end"><button pButton type="button" label="Crear Referidor" class="create" (click)="create=!create" style="background-color: #8DB91E;"></button></div>
    <p-confirmDialog #cd [style]="{width: '50vw'}">
        <ng-template pTemplate="header">
            <h3>Confirmación</h3>
        </ng-template>
        <ng-template pTemplate="footer">
            <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()" style="background-color: #8DB91E;"></button>
            <button type="button" pButton icon="pi pi-check" label="Si" (click)="cd.accept()" style="background-color: darkred;"></button>
        </ng-template>
    </p-confirmDialog>
    <p-table [value]="refers" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" responsiveLayout="scroll" currentPageReportTemplate="Mostrando {first} hasta {last} de {totalRecords} Registros" [rowsPerPageOptions]="[10,25,50]">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="type">Tipo
                    <p-sortIcon field="area"></p-sortIcon>
                </th>
                <th pSortableColumn="name_entity">Nombre Entidad
                    <p-sortIcon field="nombre"></p-sortIcon>
                </th>
                <th pSortableColumn="name">Nombre
                    <p-sortIcon field="nombre"></p-sortIcon>
                </th>
                <th pSortableColumn="surname">Apellido
                    <p-sortIcon field="apellido"></p-sortIcon>
                </th>
                <th pSortableColumn="mail">Correo
                    <p-sortIcon field="ciudad"></p-sortIcon>
                </th>
                <th pSortableColumn="phone">Teléfono
                    <p-sortIcon field="pais"></p-sortIcon>
                </th>
                <th>Acciones</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-refer>
            <tr class="referido">
                <td>{{refer.type}}</td>
                <td>{{refer.name_entity}}</td>
                <td>{{refer.name}}</td>
                <td>{{refer.surname}}</td>
                <td>{{refer.mail}}</td>
                <td>{{refer.phone}}</td>
                <td>
                  <button pButton type="button" label="Generar enlace" (click)="copy(refer.id)" style="background-color: #8DB91E; margin-right: 10px;"></button>
                  <button pButton type="button" label="Borrar datos" (click)="confirm(refer.id)" style="background-color: darkred;"></button>
                </td>
            </tr>
        </ng-template>
        <!-- <ng-template pTemplate="paginatorleft ">
            <p-button type="button " icon="pi pi-plus " styleClass="p-button-text "></p-button>
        </ng-template> -->
        <!-- <ng-template pTemplate="paginatorright ">
            <p-button type="button " icon="pi pi-cloud " styleClass="p-button-text "></p-button>
        </ng-template> -->
    </p-table>
</div>

<app-toast-link></app-toast-link>
