import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ControlService } from 'src/app/core/services/control.service';
import { environment } from 'src/environments/environment';
import { ToastLinkComponent } from '../../core/components/toast-link/toast-link.component';

interface Type {
  name: string,
  code: string
}

interface refer {
  type: string,
  name_entity: string,
  name: string,
  surname: string,
  mail: string,
  phone: string
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  @ViewChild(ToastLinkComponent) child!:any;
  create = false;
  selectedType: Type = {
    name: '',
    code: ''
  };

  types = [
    {name: 'Natural', code: 1},
    {name: 'Jurídica', code: 2},
  ]

  selected_refer = {
    type: '',
    name_entity: '',
    name: '',
    surname: '',
    mail: '',
    password: '',
    phone: '',
    type_user: '1'
  };

  refers = [];

  password = '';

  constructor(private control: ControlService, private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.getRefers();
  }

  getRefers() {
    this.control.getPeruD().then((res: any) => {
      this.refers = res;
    });
  }

  send(){
    this.selected_refer.type = this.selectedType.name;

    if (this.password != this.selected_refer.password) {
      alert ("Las contraseñas no coinciden");
      return;
    }
    this.selected_refer.password = btoa(this.selected_refer.password);
    this.control.createRef(this.selected_refer).then((res: any) => {
      window.open(environment.linkRefered+'/#/inicio/'+res, "_blank");
    });

    this.create = !this.create;
    this.getRefers();

  }

  copy(val: string){
    var link = environment.linkRefered+'/#/inicio/'+val;
    this.child.show = true;
    this.child.link = link;
    navigator.clipboard.writeText('Enlace para referidos: '+link);
  }

  deleteRefers(id:any) {
    this.control.delete(id).then((res: any) => {
      this.getRefers();
    });
  }

  confirm(id: any) {
    this.confirmationService.confirm({
        message: '¿Estas seguro de realizar esta acción? Los datos no podrán ser recuperados',
        accept: () => {
            this.deleteRefers(id)

        }
    });
}


}
