import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './core/guard/login.guard';
import { PrivateComponent } from './private/private.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path:'',
    component: PrivateComponent,
    loadChildren: () => import('./private/private-routing.module').then(m=>m.PrivateRoutingModule),
    canActivate: [LoginGuard]
  },
  {
    path: '',
    loadChildren: () => import('./public/public-routing.module').then(m=>m.PublicRoutingModule)
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
