import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import { PayulatamService } from './payulatam.service';

@Injectable({
  providedIn: 'root'
})
export class ControlService {

  constructor(private api: PayulatamService) { }

  getPeru(): Promise<any[]> {
    return new Promise((resolve, reject) => {

      firebase.firestore().collection("peru").onSnapshot((res) => {
        const resp: any[] = [];
        res.forEach(child => {
          const category: any = child.data();
          category.id = child.id;
          resp.push(category)
        });

        resolve(resp);
      });
    });
  }

  getPeruOfRef(id: string): Promise<any[]> {
    return new Promise((resolve, reject) => {

      firebase.firestore().collection("peru").where("relation", "==", id).onSnapshot((res) => {
        const resp: any[] = [];
        res.forEach(child => {
          const category: any = child.data();
          category.id = child.id;
          resp.push(category)
        });

        resolve(resp);
      });
    });
  }

  updateRef(id: string, value: number) {
    return new Promise((resolve, reject) => {
      firebase.firestore().collection("peru").doc(id).update({ value: value })
    });
  }

  getPeruD(): Promise<any[]> {
    return new Promise((resolve, reject) => {

      firebase.firestore().collection("peruD").where('type_user', '!=', '71').onSnapshot((res) => {
        const resp: any[] = [];
        res.forEach(child => {
          const category: any = child.data();
          category.id = child.id;
          resp.push(category)
        });

        resolve(resp);
      });
    });
  }


  createRef(data: any) {
    return new Promise((resolve, reject) => {
      firebase.firestore().collection("peruD").add(data).then((res) => {
        resolve(res.id);
      });
    });
  }


  valid(user: string, password: string) {
    return new Promise((resolve, reject) => {
      firebase.firestore().collection("peruD").where('mail', '==', user).where('password', '==', password).onSnapshot((res) => {
        if (res.size > 0) {
          let data: any = null;
          res.forEach((element) => {
            data = element.data();
            data.id = element.id;
          });
          resolve(data);
        } else {
          resolve(null);
        }
      });
    });
  }

  copy(id: string) {
    return new Promise((resolve, reject) => {
      firebase.firestore().collection("peruD").doc(id).onSnapshot((res) => {
        if (res.exists) {
          const d: any = res.data();
          firebase.firestore().collection("peruDBackup").doc(id).set(d).then(() => {
            firebase.firestore().collection("peru").where('relation', '==', id).onSnapshot((res1) => {
              res1.forEach((item) => {
                const d1: any = item.data();
                firebase.firestore().collection("peruBackup").doc(item.id).set(d1);
              })
              resolve(true)
            });
          })
        } else {
          resolve(true);
        }
      });
    });


  }

  delete(id: string) {
    return new Promise((resolve, reject) => {
      this.copy(id).then(() => {
        firebase.firestore().collection("peruD").doc(id).delete();
        firebase.firestore().collection("peru").where('relation', '==', id).onSnapshot((res1) => {
          res1.forEach((item) => {
            firebase.firestore().collection("peru").doc(item.id).delete();
          })
          resolve(true)
        });
      });
    })
  }

  deletePeru(id: string) {
    return new Promise((resolve, reject) => {
      firebase.firestore().collection("peru").doc(id).onSnapshot((res) => {
        const d: any = res.data();
        firebase.firestore().collection("peruBackup").doc(id).set(d).then(() => {
          firebase.firestore().collection("peru").doc(id).delete().then(() => {
            resolve(true);
          });

        })
      })
    })
  }

  getUser(id: string) {
    return new Promise((resolve, reject) => {
      firebase.firestore().collection("peruD").doc(id).onSnapshot((res) => {
        if (res.exists) {
          const d: any = res.data();
          d.id = res.id;
          resolve(d);
        } else {
          resolve(null);
        }

      })
    })
  }

  updateUser(id: string, data: any) {
    return new Promise((resolve, reject) => {
      firebase.firestore().collection("peruD").doc(id).update(data).then((res) => {
        resolve(true);

      })
    })
  }

  updatePyment() {
      this.api.getPayment().subscribe((res: any) => {
        res.forEach((element: any) => {
          
          firebase.firestore().collection("peru").where('doc', '==', Number.parseInt(element.identification)).onSnapshot((res1) => {
            res1.forEach((element1) => {
              firebase.firestore().collection("peru").doc(element1.id).update({value: +element.value});
              
            })
          })
        });
      })
  }

}
