import { Component, HostListener, OnInit } from '@angular/core';
import { FilterPipe } from 'src/app/core/pipe/filter.pipe';
import { BasicService } from 'src/app/core/services/basic.service';
import { ControlService } from 'src/app/core/services/control.service';
import { PayulatamService } from 'src/app/core/services/payulatam.service';
import { DatePipe } from '@angular/common';
import { FilterRefPipe } from 'src/app/core/pipe/filter-ref.pipe';
import { environment } from 'src/environments/environment';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  userActivity: any;
  userInactive: Subject<any> = new Subject();

  options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    headers: ['Fecha', 'Cedula', 'Nombre', 'Apellido', 'Ciudad', 'Pais', 'Telefono', 'Correo', 'Referidor'],
    showTitle: false,
    useBom: true,
    removeNewLines: false,
    keys: [],
    filename: 'hola',
    showLabels: false,
    title: ''
  };

  executed = true;

  data: any[] = [];

  type = sessionStorage.getItem('type');
  id = sessionStorage.getItem('id');

  users = [];

  statuses = [
    { label: 'Pendiente', value: 1 },
    { label: 'En proceso', value: 2 },
    { label: 'Finalizada', value: 3 },
  ]
  value: any = null;
  value1: any = null;
  filter: string = '';
  time: any = null;
  timeout : any = null;

  constructor(private control: ControlService, private messageService: BasicService, private payu: PayulatamService,
    private pipe: FilterPipe, public datepipe: DatePipe, private pipiRef: FilterRefPipe, private confirmationService: ConfirmationService,
    private router:Router) {
      this.setTimeout();
      this.userInactive.subscribe(() => {
        clearInterval(this.time);
        this.timeout = setTimeout(() => {
          sessionStorage.clear();
          this.router.navigate(['/login']);
        }, 60000);
        this.confirmationService.confirm({
          message: '¿Sigues ahí?',
          accept: () => {
            clearInterval(this.time);
            clearTimeout(this.timeout);
            this.getPayment();
          }
        });
      });
    }

  ngOnInit(): void {
    this.getPayment();
    
    
    if (this.type == "71") {
      this.getRecords();
    } else {
      this.getRecordsFiltered();
    }

  }

  ngAfterViewInit(): void {
    this.getInfoPay();
  }

  ngAfterViewChecked(): void {
    var test = document.getElementsByTagName('BUTTON');

    if (test != undefined && this.type == '71') {
      test[0].innerHTML = "Descargar Excel";
      test[0].setAttribute("style", "display: grid;background-color: #8DB91E; width: 100%");
      test[0].setAttribute("class", "btn btn-info btn-lg");
    }
  }

  getRecords() {
    this.control.getPeru().then((res: any) => {
      this.users = res;
      this.data = res;


      if (this.executed) {
        this.executed = false;
        this.getInfoPay();
      }
      this.data = this.users
        .map((u: any) => ({
          fecha: this.datepipe.transform(u.date, 'dd-MM-yyyy'),
          cedula: u.doc,
          nombre: u.name,
          apellido: u.surname,
          ciudad: u.city,
          pais: u.country,
          telefono: u.tel,
          correo: u.mail,
          referidor: u.parent
        }));
    });
  }

  getData() {
    this.data = this.pipiRef.transform(this.pipe.transform(this.users, this.value, this.value1), this.filter)
      .map((u: any) => ({
        fecha: this.datepipe.transform(u.date, 'dd-MM-yyyy'),
        cedula: u.doc,
        nombre: u.name,
        apellido: u.surname,
        ciudad: u.city,
        pais: u.country,
        telefono: u.tel,
        correo: u.mail,
        referidor: u.parent
      }));
  }

  getRecordsFiltered() {
    const id = sessionStorage.getItem('id');
    if (id != undefined) {
      this.control.getPeruOfRef(id).then((res: any) => {
        this.users = res;
        if (this.executed) {
          this.executed = false;
          this.getInfoPay();
        }
      });
    }
  }

  copy() {
    var link = environment.linkRefered + '/#/inicio/' + this.id;
    navigator.clipboard.writeText('Enlace para referidos: ' + link);
    this.messageService.presentToast('success', 'Enlace copiado');
  }

  getInfoPay() {

    var data: any = this.users.filter((u: any) => u.value == -1 || u.value == undefined);

    data = data.map((u: any) => ({ id: u.id, cedula: u.doc.toString() }));

    this.payu.getInfo(data).subscribe(
      (res: any) => {

        res.forEach((r: any) => {

          if (r.valor != -1 || res.valor == undefined) {
            this.control.updateRef(r.id, +r.valor);
          }
        })
        if (this.type == "71") {
          this.getRecords();
        } else {
          this.getRecordsFiltered();
        }
      }
    );
  }

  calculateComission(value: number) {

    switch (value) {
      case (14): {
        return 2
      }
      case (49): {
        return 12
      }
      case (130): {
        return 30
      }
      default: {
        return 0;
      }
    }
  }

  confirm(id: any) {
    this.confirmationService.confirm({
      message: '¿Estas seguro de realizar esta acción? Los datos no podrán ser recuperados',
      accept: () => {
        this.deleteRefers(id);

      }
    });
  }

  deleteRefers(id: any) {
    this.control.deletePeru(id).then((res: any) => {
      if (this.type == "71") {
        this.getRecords();
      } else {
        this.getRecordsFiltered();
      }
    });
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 180000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

getPayment() {
  this.time = setInterval((res: any) => {
    this.control.updatePyment();
  },30000);
}

}
