import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from './filter.pipe';
import { FilterRefPipe } from './filter-ref.pipe';
import { CommisionPipe } from './commision.pipe';



@NgModule({
  declarations: [
    FilterPipe,
    FilterRefPipe,
    CommisionPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [FilterPipe, FilterRefPipe, CommisionPipe]
})
export class PipeModule { }
