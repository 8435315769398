import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { MenuSideBarComponent } from './menu-side-bar/menu-side-bar.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { FooterComponent } from './footer/footer.component';
import { ControlSidebarComponent } from './control-sidebar/control-sidebar.component';



@NgModule({
  declarations: [
    HeaderComponent,
    MenuSideBarComponent,
    MenuItemComponent,
    FooterComponent,
    ControlSidebarComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    HeaderComponent,
    MenuSideBarComponent,
    FooterComponent
  ]
})
export class ComponentsModule { }
