<nav class="main-header navbar navbar-expand navbar-white navbar-light justify-content-center">

    <ul class="navbar-nav position-absolute start-1">
        <li class="nav-item">
            <a class="nav-link" (click)="toggleMenuSidebar.emit()" role="button"><i class="fas fa-bars"></i
            ></a>
        </li>
        <!-- <li class="nav-item d-none d-sm-inline-block">
            <a [routerLink]="'/'" class="nav-link">Home</a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
            <a [routerLink]="'/blank'" class="nav-link">Contact</a>
        </li> -->
    </ul>

    <img src="../../assets/img/logo.svg" alt="Logo" width="200px">

    <form class="form-inline ml-3" *ngIf="false">
        <div class="input-group input-group-sm">
            <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
            <div class="input-group-append">
                <button class="btn btn-navbar" type="submit">
                    <i class="fas fa-search"></i>
                </button>
            </div>
        </div>
    </form>

    <!-- <ul class="navbar-nav ml-auto">
         <app-messages></app-messages>
        <app-notifications></app-notifications>
        <app-language></app-language>
        <app-user></app-user> -
        <li class="nav-item">
            <button class="nav-link" (click)="onToggleControlSidebar()">
                <i class="fas fa-th-large"></i>
            </button>
        </li>
    </ul> -->
</nav>