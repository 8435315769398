import { Component, HostBinding, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss']
})
export class PrivateComponent implements OnInit {
  public sidebarMenuOpened = true;
  @HostBinding('class') class = 'wrapper';
  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
  }

  toggleMenuSidebar() {
    if (this.sidebarMenuOpened) {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'sidebar-open'
        );
        this.renderer.addClass(
            document.querySelector('app-root'),
            'sidebar-collapse'
        );
        this.sidebarMenuOpened = false;
    } else {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'sidebar-collapse'
        );
        this.renderer.addClass(
            document.querySelector('app-root'),
             'sidebar-open'
        );
        this.sidebarMenuOpened = true;
    }
}

}
