<div class="content-toast position-fixed bottom-3 end-0 p-3" style="z-index: 11">
    <div id="liveToast" class="toast" [hidden]="!show" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
            <strong class="me-auto w-100">
                <span class="mr-2">Link del referido copiado</span> 
                <i class="fas fa-copy"></i
      ></strong>
            <button type="button" class="btn float-right" (click)="show = false" aria-label="Close">
        <i class="fas fa-times text-white"></i>
      </button>
        </div>
        <div class="toast-body">
            <input class="form-control" type="text" name="link" id="refLink" [value]="link" disabled />
        </div>
    </div>
</div>