import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any[], start: any, end: any, ...args: unknown[]): any[] {
    if (start == null || end == null) {
      return value;
    }
    return value.filter((item: any) => {
      const date = new Date(item.date);
      const date2 = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
      const startD = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0);
      const endD = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 0, 0, 0);
      return date2>= startD && date2<=endD;
    });
  }

}
