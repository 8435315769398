<div class="container-fluid">
  <div class="wrapper fadeInDown">
    <div id="formContent">
      <div class="fadeIn first py-4">
        <img src="../../assets/img/logo.svg" id="icon" alt="User Icon" />
        <br /><br />
      </div>
      <h5 class="text-center">Registra tus datos como Referidor</h5>
      <br />

      <div class="container">
        <div class="row">
          <div class="col" *ngIf="selectedType.code == 2">
            <span class="p-float-label">
              <input
                id="float-input2"
                type="name"
                [(ngModel)]="selected_refer.name_entity"
                pInputText
              />
              <label for="float-input2">Nombre Entidad</label>
            </span>
          </div>
          <div class="col">
            <span class="p-float-label">
              <input
                id="float-input3"
                type="name"
                [(ngModel)]="selected_refer.name"
                pInputText
              />
              <label for="float-input3">Nombres</label>
            </span>
          </div>
          <div class="col">
            <span class="p-float-label">
              <input
                id="float-input4"
                type="name"
                [(ngModel)]="selected_refer.surname"
                pInputText
              />
              <label for="float-input4">Apellidos</label>
            </span>
          </div>
          <div class="col">
            <p-dropdown
              [options]="types"
              [style]="{ width: '100%' }"
              [(ngModel)]="selectedType"
              optionLabel="name"
            ></p-dropdown>
          </div>
          <div class="col">
            <span class="p-float-label">
              <input
                id="float-input5"
                type="email"
                [(ngModel)]="selected_refer.mail"
                pInputText
              />
              <label for="float-input5">Correo</label>
            </span>
          </div>
          <div class="col">
            <span class="p-float-label">
              <input
                id="float-input1"
                type="phone"
                [(ngModel)]="selected_refer.phone"
                pInputText
              />
              <label for="float-input1">Teléfono</label>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <span class="p-float-label">
              <input
                id="float-input6"
                type="password"
                [(ngModel)]="selected_refer.password"
                pPassword
              />
              <label for="float-input6">Contraseña</label>
            </span>
          </div>
          <div class="col">
            <span class="p-float-label">
              <input
                id="float-input7"
                type="password"
                [(ngModel)]="password"
                pPassword
              />
              <label for="float-input7">Repite tu contraseña</label>
            </span>
          </div>
        </div>
        <div class="row d-flex justify-content-between mx-auto">
          <div class="col">
            <button
              pButton
              type="button"
              label="Actualizar"
              class="p-button bg-btn-primary"
              (click)="send()"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
