import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ControlService } from 'src/app/core/services/control.service';

interface Type {
  name: string,
  code: string
}

@Component({
  selector: 'app-create-refer',
  templateUrl: './create-refer.component.html',
  styleUrls: ['./create-refer.component.scss']
})
export class CreateReferComponent implements OnInit {
  selectedType: Type = {
    name: '',
    code: ''
  }; 

  types = [
    {name: 'Natural', code: 1},
    {name: 'Jurídica', code: 2},
  ]

  selected_refer = {
    type: '',
    name_entity: '',
    name: '',
    surname: '',
    mail: '',
    password: '',
    phone: '',
    type_user: '1'
  }; 

  refers = [];

  password = '';

  constructor(private control: ControlService, private router: Router) { }

  ngOnInit(): void {
  }


  send(){
    this.selected_refer.type = this.selectedType.name;

    if (this.password != this.selected_refer.password) {
      alert ("Las contraseñas no coinciden");
      return;
    }
    this.selected_refer.password = btoa(this.selected_refer.password);
    this.control.createRef(this.selected_refer).then((res: any) => {      
      this.router.navigate(['/login']);
    });
    
  }
}
