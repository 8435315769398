import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-toast-link',
  templateUrl: './toast-link.component.html',
  styleUrls: ['./toast-link.component.scss']
})
export class ToastLinkComponent implements OnInit {
  show = false;
  link = "";
  constructor() {
   }

  ngOnInit(): void {
  }
}
