<a (click)="handleMainMenuAction()" class="nav-link my-1 w-100" [ngClass]="{active: isMainActive || isOneOfChildrenActive}">
    <i class="nav-icon fas fa-tachometer-alt mr-3"></i>
    <p>{{ menuItem.name }}</p>
    <i *ngIf="isExpandable && menuItem.children.length > 0" class="right fas fa-angle-left"></i>
</a>
<ul class="nav nav-treeview" *ngFor="let item of menuItem.children">
    <li class="nav-item">
        <a id="tree+'item'" [routerLink]="item.path" [routerLinkActive]="'active'" class="nav-link">
            <i class="far fa-circle nav-icon"></i>
            <p>{{ item.name }}</p>
        </a>
    </li>
</ul>
