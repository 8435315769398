import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class BasicService {

  constructor(private messageService: MessageService) { }

  presentToast(type: "success"|"info"|"warn" | "error", message: string, title: string = "") {
    this.messageService.add({ severity: type, summary: title, detail: message });
  }
}
