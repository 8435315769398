import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PayulatamService {
  body = {
    data: []
  };

  constructor(private http: HttpClient) {}

  getInfo(code: any[]) {
    var body = {
      data: code
    }
    return this.http.post(
      environment.linkPayu,
      body,
    );
  }

  getPayment() {
    return this.http.get(environment.linkPayment);
  }
}
