import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  // onToggleMenuSidebar() {
  //   this.store.dispatch(new ToggleSidebarMenu());
  // }

  // onToggleControlSidebar() {
  //     this.store.dispatch(new ToggleControlSidebar());
  // }

}
