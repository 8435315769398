import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BasicService } from 'src/app/core/services/basic.service';
import { ControlService } from 'src/app/core/services/control.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user = "";
  password = "";
  url = 0;
  constructor(private router: Router, private controlService: ControlService, private basic: BasicService) { }

  ngOnInit(): void {
    this.url = window.location.href.search("referidor");
  }

  send() {
    this.controlService.valid(this.user, btoa(this.password)).then((res: any) => {
      if (res != null) {
        sessionStorage.setItem("login", "true");
        sessionStorage.setItem("type", res.type_user);
        sessionStorage.setItem("id", res.id);
        sessionStorage.setItem("mail", res.mail);
        sessionStorage.setItem("name_entity", res.name_entity);
        sessionStorage.setItem("name", res.name);
        this.router.navigate(['/home']);
      } else {
        this.basic.presentToast('warn',"Correo o contraseña incorrecto")
      }
    })
  }

}
